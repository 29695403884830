import React from 'react';
import BISvg from 'assets/svgs/hstack-brand-identity.svg';

import 'assets/scss/_reset.scss';
import 'assets/scss/_hstack-primo.scss';

function App() {
  return (
    <main className="container">
      <div className="brand-area">
        <img src={BISvg} alt="hstack logo" />
      </div>
      <div className="gnb">
        <ul className="tabs" role="tablist">
          <li role="tab">
            <a href="/compound-interest">
              <span>복리계산기</span>
            </a>
          </li>
          <li role="tab" />
          <li role="tab" />
          <li role="tab" />
        </ul>
      </div>
    </main>
  );
}

export default App;
